import { Injectable } from '@angular/core';
import{SessionService} from './SessionService';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'  
  })

export class AuthService{
   
    constructor(public Session: SessionService, private httpClient: HttpClient, private router: Router) {
       
     }  
   

    isAuthenticated() {
        this.Session.updateSession();
        return !!this.Session.currentUser || !!this.Session.currentUser.token;
    };

    logout() {
        this.Session.clearSession();
    }

    isAuthorized(authorizedRoles) {
        if (!Array.isArray(authorizedRoles))  authorizedRoles = [authorizedRoles];
        return (this.isAuthenticated() &&  this.Session.currentUser.claims.some(r=> authorizedRoles.indexOf(r) >= 0));
    };

    canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean
     {     
        return  this.authenticate(next.data.roles);
     }

    authenticate(userRole) {     
        if (!this.Session.currentUser || !this.Session.currentUser.token)  this.router.navigate(['login']);
        if (this.isAuthenticated() && (!userRole || this.isAuthorized(userRole)))   return true;
        return false; 
    }

    AuthorizeRoute(userRole){       
        return this.Session.currentUser && this.Session.currentUser.token && this.isAuthorized(userRole);
    }


}
<div >
    <h1 mat-dialog-title>{{data.title}}</h1>
    <div mat-dialog-content>
     <p class="message" [class.warn]="data.messageType==2">{{data.message}}</p> 
      
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="setDialogResult(true)" *ngIf="data.optionType==3">Okej</button>
      <button mat-button (click)="setDialogResult(true)"  *ngIf="data.optionType==1 || data.optionType==2" cdkFocusInitial>Ja</button>
      <button mat-button (click)="setDialogResult(false)"  *ngIf="data.optionType==1 || data.optionType==2">Nej</button>
      <button mat-button (click)="setDialogResult(null)" *ngIf="data.optionType==4 || data.optionType==2">Avbryt</button>
    </div>
</div>


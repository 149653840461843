import { Component} from '@angular/core';
import { ApiService } from '../Services/ApiService';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ErrorHandlerService } from '../Services/ErrorHandler';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'home',
  templateUrl: '../Views/Add.html',
  styleUrls: ['../Styles/SharedStyles.css'], 
})
export class AddComponent  {
  code:string=null;
  constructor(private activatedRoute: ActivatedRoute) { 
    this.activatedRoute.params.subscribe(routeParams => {          
      this.code= routeParams.code;      
    });
  }

  
  
  
  
 


 
}
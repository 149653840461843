<div  fxLayout="column" class="add-container">  
 <h1 class="add-title">Hejsan</h1>
 <div class="add-content">   
 Grattis till ert nya företag och hoppas att ni lyckas. För att lyckas väl behövs ett smart och enkelt verktyg som kan hantera företagets administration
 och ekonomi. FakturaSidan är en onlineapplikation  där företagare kan skapa offerter, fakturor, planering kundregister, lönespecifikation och mycket mer.
</div>
<h1 class="add-title">- FakturaSidan gör det enkelt!</h1>
<div class="add-content">
  FakturaSidan handlar inte om att skapa fakturor, det handlar om allt som skall göras före och efter fakturahandling. Du som företagare behöver göra många steg före du skickar en faktura, och kan  du behöva administrera antal saker efter fakturahandling. Du behöver koncentrera dig på  marknadsföring och på att få mer jobb och låt FakturaSidan ta hand om jobb hantering och  ekonomin i enkla musknappar.          
</div>
<h1 class="add-title">- Skapa Offert/Order </h1>
<div class="add-content">I enkla musknappar kan du skapa offert/order, välj kund, skriv det priset som du erbjuder, bifoga dokument med offerten och skicka det via e-post eller sms. Det går att fakturera direkt eller skapa uppdrag i din arbetskalender. </div>
<h1 class="add-title">- Kalender och planering i smart sätt </h1>
<div class="add-content">I arbetskalander kan du skapa jobb, uppdrag, möte. I en händelse kan du ha priser, kontakter,  anställda arbetstider, fordon och välj att skicka påminnelser till dina kunder/kontakter. Sedan när  uppdraget är klart kan du enkelt skapa faktura skickades och klart. Om du har anställda som ska  utföra jobbet då kan du/dem rapportera arbetstider som går enkelt att skapa lönespecifikation  för detta perioden.  </div>
<h1 class="add-title">- Skapa Faktura </h1>
<div class="add-content">Oavsett om du har skapat offert eller ett uppdrag du kan enkelt skapa fakturor i enkla steg välj kund och pris, skriv ut/skicka via epost eller sms, registrera betalning och påminnelser.  </div>
<h1 class="add-title-hand">Vad koster detta?</h1>
<div class="add-content" fxLayout="row" >Basnivå koster 0kr per månad, där kan du skapa upp till 10 fakturor per månad, för mer fakturor och mer tjänster koster 72kr/månad. Läs mer <a href="#/subscriptions">abonnemang</a></div>
<h1 class="add-title">Registrera Gratis!</h1>
<div  fxLayout="column">
    <div class="add-content">Registrera företaget i enkla steg, gå in på www.fakturasidan.se sedan klick på register. använd koden: <strong>{{code}}</strong> </div>
    <a style="padding-top: 20px;" href="https://www.fakturasidan.se/invoice/#/register?registrationcode={{code}}">eller tryck här</a>
</div>
</div>
import { Component, AfterViewInit, ElementRef } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { Router } from '@angular/router';
import { SessionService } from '../Services/SessionService';
import { ApiService } from '../Services/ApiService';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ErrorHandlerService } from '../Services/ErrorHandler';
import { MockedData } from '../Services/MockedData';

@Component({
  selector: 'home',
  templateUrl: '../Views/Home.html',
  styleUrls: ['../Styles/SharedStyles.css'],
  animations: [
    trigger('trans', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('700ms', style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate('300ms', style({ opacity: 0 }))
      ])
    ])   
  ]
})
export class HomeComponent  {
  infoModel;
  months= ['January', 'February', 'Mars', 'April', 'May', 'Jun', 'July', 'August', 'September', 'October', 'November', 'December'];
  monthsArray= ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
 
  options:any={   
    messagesExpanded: false,
    year: new Date().getFullYear(), 
    invoicesChart:{
      colorScheme :{domain: ['#87CEFA', '#9370DB', '#FA8072', '#FF7F50', '#90EE90', '#9370DB']},
      view: [300, 250],           
      showLabels: true,
      showXAxis: true,
      showYAxis: true,
      gradient: false,
      showLegend:false,
      showXAxisLabel: true,
      xAxisLabel: "Fakturor " + new Date().getFullYear(),
      showYAxisLabel: true,     
      yAxisLabel: '', 
      timeline: true,
      legendPosition: 'below',
      invoices:[]
    },
    incomeChart:{
      view: [500, 350],
      colorScheme :{domain: ['#87CEFA', '#9370DB', '#FA8072', '#FF7F50', '#90EE90', '#9370DB', '#87CEFA', '#9370DB', '#FA8072', '#FF7F50', '#90EE90', '#9370DB']},
      showXAxis: true,
      showYAxis:  true,
      gradient:  false,
      showLegend:  false,
      showXAxisLabel:  true,
      yAxisLabel: '',
      showYAxisLabel: true,
      xAxisLabel:  "Inkomst "+ new Date().getFullYear(),
      legendPosition: 'below',
      months:[]
    }
  }
  
  constructor(private apiService: ApiService,private el:ElementRef, private mocked: MockedData, private ngxService: NgxUiLoaderService, public errorHandler: ErrorHandlerService) {  
    if(new Date().getMonth() < 5) this.options.year--;
    this.nextYear(0);  
    let s= window.innerWidth/1.35;
    this.options.incomeChart.view = [( s > 500)? 500 : s, 350];
   
  }  
  
  resetError(){
    this.options.errors={};   
  }

  onResize(event) { 
    let size = event.target.innerWidth/1.35;   
   // this.options.invoicesChart.view = [( size > 500)? 500 : size, 350];
    this.options.incomeChart.view = [( size > 500)? 500 : size, 350];
    
}

  nextYear(v:number){
   
    if((this.options.year + v) > new Date().getFullYear()) return;
    this.options.year = this.options.year + v;  
    this.infoModel = this.mocked.GetDataPerYear(this.options.year); 
    this.buildInvoicesChartParameters();
  
  }

  buildInvoicesChartParameters(){   
    this.options.invoicesChart.invoices=[];
    if(this.infoModel.paidInvoices)this.options.invoicesChart.invoices.push({name: 'Betalda fakturor', value: this.infoModel.paidInvoices});
    if(this.infoModel.nonPaidInvoices)this.options.invoicesChart.invoices.push({name: 'Obetalda', value: this.infoModel.nonPaidInvoices});
    if(this.infoModel.canceledInvoices)this.options.invoicesChart.invoices.push({name: 'Makulerde', value: this.infoModel.canceledInvoices});        
    this.options.invoicesChart.xAxisLabel = this.infoModel.invoicesCount + ' fakturor ' + this.options.year;    
    this.options.incomeChart.months=[];
    let sum= 0;
    this.infoModel.income.months.forEach(m => {
     if(m.value.totalAmount) this.options.incomeChart.months.push({name: this.monthsArray[m.month-1], value: m.value.totalAmount})
     sum += ~~m.value.totalAmount;
    });
    this.options.incomeChart.xAxisLabel= 'Inkomst ' + this.options.year + " (" + sum + ")";   
   
  }


 
}
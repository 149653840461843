import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageDialogModel } from 'src/app/Models/MessageDialogModel';

@Component({
    selector: 'MessageDialog',
    templateUrl: '../../Views/Dialogs/MessageDialog.html',
    styleUrls: ['../../Styles/SharedStyles.css'],
  })
  export class MessageDialog {
    
    constructor(public dialogRef: MatDialogRef<MessageDialog>,  @Inject(MAT_DIALOG_DATA) public data: MessageDialogModel) {}
         
      setDialogResult(value): void {         
            this.dialogRef.close(value);
       }
       
      
    
  }
<ngx-ui-loader [loaderId]="'mainLoader'"></ngx-ui-loader>
<div  fxLayout="column" fxFlex.xl="65" fxFlex.lg="70" fxFlex.md="78" fxFlex.sm="95" fxFlex.xs="98" fxFlexOffset="15"  fxFlexOffset.sm="1" fxFlexOffset.xs="1">  
  <div fxLayout="column" style="margin-bottom: 100px; min-width: 350px; overflow-x: auto;" [@trans]>
    <div fxLayout="column" style="padding-left: 20px;">
      <span class="font-large">fakturasidan.se</span>
      <h1 style="font-size: xx-large; color:rgb(61, 170, 170)">Sveriges enklaste onlinefaktura och företagsadministration</h1>
      <h2>I basnivå 'Det är gratis och ska alltid vara gratis'</h2>
      <span class="intro">Som företagare du kan enkelt skapa och redigera offerter/order, skapa kalenderuppdrag med arbetstider, fakturor och lönespecifikationer, skriva ut eller skicka dem via e-post, registrera betalningar <br/> 
         och skapa påminnelser. Du kan även exportera RUT/ROT-avdrag som xml-fil, som går att importera i skatteverkets e-tjänst. <br/> 
          Utöver detta kan du utforma kundregister, artikelregister, automatiskt sms bokningspåminnelse och mycket mer.
          I gratis basnivå kan du skapa upp till 10 fakturor per månad och några offerter. <br/> 
          Ingen bindningstid och avsluta när du vill.<br/> 
         </span>
          <div style="margin-top: 50px;">
           <h3 style="color:rgb(57, 116, 194)">  <mat-icon>security</mat-icon>Dina uppgifter och fakturor är helt säkrade då säkerhetskopiering sker flera gånger per dag.</h3>
          </div>
    </div>
    <div class="chart-container">
      <div fxLayout="row"  style="padding-left: 20px;">
        <mat-button-toggle-group>
          <mat-button-toggle ><mat-icon>keyboard_arrow_left</mat-icon></mat-button-toggle>
          <mat-button-toggle readonly>{{options.year}}</mat-button-toggle>
          <mat-button-toggle ><mat-icon>keyboard_arrow_right</mat-icon></mat-button-toggle>
        </mat-button-toggle-group>
        <span></span>
      </div>
      <div (window:resize)="onResize($event)" id="chartdiv" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="padding-top: 20px;">
        <!--
        <ngx-charts-bar-vertical
        [view]="options.invoicesChart.view"
        (window:resize)="onResize($event)"
        [scheme]="options.invoicesChart.colorScheme"
        [results]="options.invoicesChart.invoices"
        [gradient]="options.invoicesChart.gradient"
        [xAxis]="options.invoicesChart.showXAxis"
        [yAxis]="options.invoicesChart.showYAxis"
        [legend]="options.invoicesChart.showLegend"
        [showXAxisLabel]="options.invoicesChart.showXAxisLabel"
        [showYAxisLabel]="options.invoicesChart.showYAxisLabel"
        [xAxisLabel]="options.invoicesChart.xAxisLabel"
        [yAxisLabel]="options.invoicesChart.yAxisLabel" [legendPosition]="options.invoicesChart.legendPosition">
        </ngx-charts-bar-vertical>
      -->
        <ngx-charts-bar-vertical
        [view]="options.incomeChart.view"
        (window:resize)="onResize($event)"
        [scheme]="options.incomeChart.colorScheme"      
        [results]="options.incomeChart.months"     
        [xAxis]="options.incomeChart.showXAxis"
        [yAxis]="options.incomeChart.showYAxis"
        [legend]="options.incomeChart.showLegend"
        [showXAxisLabel]="options.incomeChart.showXAxisLabel"
        [showYAxisLabel]="options.incomeChart.showYAxisLabel"
        [xAxisLabel]="options.incomeChart.xAxisLabel"
        [yAxisLabel]="options.incomeChart.yAxisLabel" 
        [legendPosition]="options.incomeChart.legendPosition">
      </ngx-charts-bar-vertical>
       <div style="padding-left: 50px;">
        <!--<img width="250" height="350" src="../../assets/images/invoice_pdf.png">-->
       </div>
    
       </div>
    </div>
  
  </div>
 
 

</div>


  
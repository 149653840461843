import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { MAT_LABEL_GLOBAL_OPTIONS, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import { MatIconRegistry } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule, MatNavList } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { HttpClientModule  } from '@angular/common/http';
import {RouterModule} from '@angular/router';
import { NgxUiLoaderModule, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
const ngxUiLoaderConfig: NgxUiLoaderConfig =
{
  "bgsColor": "red",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 60,
  "bgsType": "chasing-dots",
  "blur": 0,
  "delay": 0,
  "fastFadeOut": true,
  "fgsColor": "rgba(97,173,219,0.38)",
  "fgsPosition": "center-center",
  "fgsSize": 150,
  "fgsType": "chasing-dots",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 70,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(231,231,231,0.6)",
  "pbColor": "#4aa8df",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": false,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 300
}

/*Services*/ 
import { AuthService } from './Services/AuthService';
import { ApiService } from './Services/ApiService';
import { AddressService } from './Services/AddressService';
import { IconsInjectorService } from './Services/IconsInjectorService';
import { ErrorHandlerService } from './Services/ErrorHandler';

/*Directives*/

/*Dialogs*/
import { MessageDialog } from './Components/Dialogs/MessageDialog';
/*Components*/
import { MainComponent } from './Components/MainComponent';
import {HomeComponent} from './Components/HomeComponent';
import { MockedData } from './Services/MockedData';
import { ContactComponent } from './Components/ContactComponent';
import { SubscriptionComponent } from './Components/SubscriptionComponent';






@NgModule({
  declarations: [
    MainComponent,  
    HomeComponent,
    ContactComponent,
    SubscriptionComponent,
    MessageDialog,
    
  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,  
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatListModule, 
    FlexLayoutModule,
    MatMenuModule,
    FormsModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),    
    MatFormFieldModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule, 
    MatGridListModule, 
    MatTooltipModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatBadgeModule,
    MatPaginatorModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    MatButtonToggleModule
    
  ],
  providers: [    
    AuthService, 
    ApiService,
    AddressService,   
    ErrorHandlerService,
    IconsInjectorService,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    MockedData,
 
  ],
  bootstrap: [
    MainComponent
   
  ]
})
export class AppModule { }


import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
@Injectable({providedIn: 'root'})
export class SessionService{
    currentUser:any;
  
    constructor() {
       
     }

    updateSession(){
        let data = localStorage.getItem('sessionData');       
        if (data) {
            this.currentUser = JSON.parse(data);
        }
    }
    clearSession(){
        this.currentUser= null;
        localStorage.clear();
    }
    
    saveSession(sessionData){
        localStorage.setItem('sessionData', JSON.stringify(sessionData));       
        this.currentUser = sessionData;
    }
    saveSessionLanguage(lg:string){
        localStorage.setItem('systemLanguage', lg);
    }
    getSessionLanguage(){
        let lg = localStorage.getItem('systemLanguage');      
        return  lg? lg : 'Svenska';
       
    }
    saveUserHasRApp(value:boolean){
        localStorage.setItem('userHasRut', value.toString());
    }
    getUserHasRApp(){
       return  localStorage.getItem('userHasRut') == 'true';      
       
    }
}
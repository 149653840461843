<ngx-ui-loader [loaderId]="'mainLoader'"></ngx-ui-loader>
<div  fxLayout="column" fxFlex.xl="65" fxFlex.lg="70" fxFlex.md="78" fxFlex.sm="95" fxFlex.xs="98" fxFlexOffset="15"  fxFlexOffset.sm="5" fxFlexOffset.xs="5">  
  
    <div fxLayout="column" [@trans] >
      <div fxLayout="column">
        <span class="font-large">Kontakta oss</span>
      </div>
        <mat-list>
          <mat-divider></mat-divider> 
          <p>  <mat-icon> email</mat-icon> Skicka till oss så svara vi dina frågor!</p> 
        
          <div *ngIf="emailBody"> 
            <div style="position: relative"> <ngx-ui-loader [loaderId]="'emailsendloader'"></ngx-ui-loader></div>         
            <form style="padding-left: 20px;">
              <span></span>
              <p>          
                <mat-form-field [class.has-error]="errors.companyNameError" >   
                  <input matInput type="text" name="companyname"  placeholder="Företagsnamn" maxlength="40" [(ngModel)]="emailBody.companyName" (ngModelChange)="resetError()" required>
                  <mat-hint class="error">{{errors.companyNameError}}</mat-hint>
                </mat-form-field>
              </p>
              <p>
                <mat-form-field [class.has-error]="errors.replyToError">   
                  <input type="text" matInput name="replyto"  placeholder="e-post" maxlength="40" [(ngModel)]="emailBody.replyTo" (ngModelChange)="resetError()" required>
                  <mat-hint class="error">{{errors.replyToError}}</mat-hint>
                </mat-form-field>
              </p>
              <p>
                <mat-form-field [class.has-error]="errors.emailTextError" style="width: 90%;"> 
                  <mat-label>Meddelande</mat-label>
                  <textarea matInput name="emailtext" maxlength="500"  cdkTextareaAutosize  cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5" [(ngModel)]="emailBody.emailText" (ngModelChange)="resetError()" required></textarea>  
                  <mat-hint class="error">{{errors.emailTextError}}</mat-hint>
                </mat-form-field>
              </p>
              <p>
                <mat-card-actions>   
                  <button mat-raised-button  (click)="sendEmail()"   color="primary">Skicka</button>   
                </mat-card-actions>  
              </p>
            </form>
          </div>             
        </mat-list>        
      </div>
      <div>
        <mat-divider></mat-divider>
        <div class="contact">
          <p><mat-icon>phone</mat-icon> 076-55 88 430</p> 
          <p><mat-icon> location_on</mat-icon> Örvägen 9, 194 52 Upplands Väsby</p> 
          <p><mat-icon>label</mat-icon> TEKGEE SOFT AB</p>    
        </div>
         
        
      </div>
</div>

export class MessageDialogModel{   
    constructor( 
          message:string, 
          messageType:MessageType,           
          messageOptionType:MessageOptionType,
          title:string=null
         ){
        this.messageType = messageType;
        this.title=title;
        this.message=message;
        this.optionType=messageOptionType? messageOptionType: 1;
      
     }
     /** mclass:string 'Info' | 'warn' | 'choose', */
    messageType:MessageType;
    title:string;
    message:string;
    /** type:string  'YesNo' | 'YesNoCancel' | Ok | OkCancel*/
    optionType:MessageOptionType;
   
}
export enum MessageOptionType {
    YesNo=1,
    YesNoCancel,
    Ok,
    OkCancel    
  }
export enum MessageType {
    Info=1,
    Warn,
    Choose    
  }
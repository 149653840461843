import { Component } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { ApiService } from '../Services/ApiService';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ErrorHandlerService } from '../Services/ErrorHandler';
import { MockedData } from '../Services/MockedData';
import { MessageType, MessageOptionType, MessageDialogModel } from '../Models/MessageDialogModel';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialog } from './Dialogs/MessageDialog';

@Component({
  selector: 'home',
  templateUrl: '../Views/Subscription.html',
  styleUrls: ['../Styles/SharedStyles.css'],
  animations: [
    trigger('trans', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('700ms', style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate('300ms', style({ opacity: 0 }))
      ])
    ])   
  ]
})
export class SubscriptionComponent {
  
  options:any={};  
  constructor(private apiService: ApiService, public dialog: MatDialog, private mocked: MockedData, private ngxService: NgxUiLoaderService, public errorHandler: ErrorHandlerService) {  
    
  } 

  showSubMessage(){
    let messageModel:MessageDialogModel= new MessageDialogModel("Vi håller och updatera system. Det går inte att välj den här abonnemang", MessageType.Info, MessageOptionType.Ok, "Abonnemang");
    this.dialog.open(MessageDialog, { width: '350px', data: messageModel}).afterClosed().subscribe(result => { });
  }
 
 


 
}
import { Injectable } from '@angular/core';
import{SessionService} from './SessionService';
import { Router } from '@angular/router';
import { SystemError } from '../Models/ErrorModel';


@Injectable({
    providedIn: 'root'  
  })

export class ErrorHandlerService {

    public error:SystemError;
    constructor(public Session: SessionService,  private router: Router) {
        this.error= new SystemError();
    }
   
    public HandelError(res){

     
        // logout user and redirect to login page in case of unauthorized
        window.scrollTo(0, 0);
        this.error.BindErrorResponse(res); 
        /*        
        if(res.status==401){
           
            this.error.message = "inloggningssessionen har gått ut! du måste logga in igen";           
            this.Session.clearSession();
            this.router.navigate(['login']);            
        }
        */
             
        setTimeout(() => {           
            this.error= new SystemError();
        }, 3000);

    }
   







}
import { Component } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { ApiService } from '../Services/ApiService';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ErrorHandlerService } from '../Services/ErrorHandler';
import { MockedData } from '../Services/MockedData';
import { MessageDialogModel, MessageType, MessageOptionType } from '../Models/MessageDialogModel';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialog } from './Dialogs/MessageDialog';
import { EmailBody, EmailType } from '../Models/EmailBody';

@Component({
  selector: 'home',
  templateUrl: '../Views/Contact.html',
  styleUrls: ['../Styles/SharedStyles.css'],
  animations: [
    trigger('trans', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('700ms', style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate('300ms', style({ opacity: 0 }))
      ])
    ])   
  ]
})
export class ContactComponent {

  emailBody:EmailBody;
  errors:any={};
  
  constructor(private apiService: ApiService, public dialog: MatDialog, private mocked: MockedData, private ngxService: NgxUiLoaderService, public errorHandler: ErrorHandlerService) {  
    this.resetEmailBody();  
  } 
 
  
  resetError(){
    this.errors={};
    
  }

  sendEmail(){
    
    if (this.emailBody.replyTo == '') { this.errors.replyToError = 'obligatoriskt fält'; return; }
   
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailBody.replyTo)){
      this.errors.replyToError = 'Fel e-post format'; return;
     }
     if (this.emailBody.emailText == '') { this.errors.messageError = 'obligatoriskt fält'; return; }
     this.rebuildEmailForm();
    this.ngxService.startLoader('emailsendloader');
    this.apiService.email.sendEmail(this.emailBody).then(response=> {     
      this.ngxService.stopLoader('emailsendloader');
      let messageModel:MessageDialogModel= new MessageDialogModel("Tack, vi har fått ditt e-postmeddelande och kommer att svara så snart som möjligt.", MessageType.Info, MessageOptionType.Ok, "E-POST");
      this.dialog.open(MessageDialog, { width: '350px', data: messageModel}).afterClosed().subscribe(result => { 
        this.resetEmailBody(); 
        this.errors={}; 
      });
           
    }).catch((err) => {       
        this.errorHandler.HandelError(err); 
        this.ngxService.stopLoader('emailsendloader');      
    });
   
  }

  rebuildEmailForm(){
   this.emailBody.subject= 'External Message from ' + this.emailBody.companyName; 
   this.emailBody.title = 'External Message from ' + this.emailBody.companyName;
   this.emailBody.body = [];
   var lines = this.emailBody.emailText.split('\n');   
   for (var i = 0; i < lines.length; i++) {
    this.emailBody.body.push(lines[i]);
   } 
   this.emailBody.body.push('sender Email: ' + this.emailBody.replyTo)
   
  }

  resetEmailBody(){
    this.emailBody={
      receiverEmail: 'info@fakturasidan.se',
      replyTo: '',
      subject: '',
      emailText: '',
      footerText: '',
      body: [],
      footer: [],
      links:[],
      type:EmailType.Undefined,
      title: 'External Email',
      attachments: [],
      companyName: '',
    }
  }


 

 
}
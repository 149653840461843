import { Component } from '@angular/core';
import {SessionService} from '../Services/SessionService';
import { Router } from '@angular/router';
import { ErrorHandlerService } from '../Services/ErrorHandler';
import { AuthService } from '../Services/AuthService';
import { IconsInjectorService } from '../Services/IconsInjectorService';
import { ApiService } from '../Services/ApiService';

@Component({
  selector: 'app-root',
  templateUrl: '../Views/MainView.html',
  styleUrls: ['../Styles/SharedStyles.css']
})
export class MainComponent {
  
  languages:Array<any>;
  
  constructor(
    public session: SessionService, 
    private router: Router,     
    public authService: AuthService,  
    public errorHandler: ErrorHandlerService,
    private iconsInjector: IconsInjectorService,
    private apiService: ApiService,

   ) 
    {

      this.iconsInjector.InjectIcon('login', './assets/images/login.svg');  
      this.iconsInjector.InjectIcon('register', './assets/images/register.svg'); 
    
   }
   ngOnInit(){
    this.apiService.analytics.registerNewVisitors().then((data)=>{}).catch((err)=>{});  
  }

  
  




}

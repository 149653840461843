import { Injectable, ElementRef, Renderer2, RendererFactory2} from '@angular/core';
import { ArrayType } from '@angular/compiler';
import { ApiService } from './ApiService';
import { AddressModel } from '../Models/AddressModel';

@Injectable()
export class AddressService   {
    private renderer: Renderer2;

    constructor(private apiService: ApiService, rendererFactory: RendererFactory2){
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    addresses = [];

    public getAddress(address: AddressModel, el: Element= null) {
        if (!address.street) return;
        this.addresses = [];
        // the sddress api service doesn't accept number with letters like lillbybacken 5C, %C will not be accepted
        var st = address.street;
        var str = "";
        var swt = false;
        for (var i = 0; i < st.length; i++) {
            if (this.isNumber(st[i])) swt = true;
            if (swt && !this.isNumber(st[i])) break;
            str += st[i];
        }
        st = str.toString().replace(/ /g, '+');       
        if(el != null)  this.renderer.addClass(el,"loading-gif");    
        this.apiService.helpers.getAddress(st).then((data) => {   
            if(el != null)  this.renderer.removeClass(el,"loading-gif");
            if (!data) return;
            if (data.result.length == 1) {
              //  var splt = address.street.split(' ');
              //  if (splt.length) address.street = data.result[0].street + ' ' + splt[splt.length - 1];
                address.zipcode = data.result[0].zipcode;
                address.town = data.result[0].city;               
            } else if (data.result.length > 1) {
                data.result.map(function(x){this.addresses.push(x)});              
            }
        }).catch((err) => {   
            if(el != null) this.renderer.removeClass(el,"loading-gif");        
        });   
           

    
    }


    public getCity (address: AddressModel, el: Element= null) {

        if (address.zipcode.replace(/ /g, '').length != 5) { return; }
        var zipcode = address.zipcode.toString().replace(/ /g, '');

        if (this.addresses.length) {
            var adrs = this.addresses.find(function (c) { return c.zipcode == address.zipcode; });
            if (adrs) { address.town = adrs.city; return; }
        }


        zipcode = [zipcode.slice(0, 3), '+', zipcode.slice(3)].join('');
        if(el != null)  this.renderer.addClass(el,"loading-gif");    
        this.apiService.helpers.getCity(zipcode).then((data) => {   
            if(el != null) this.renderer.removeClass(el,"loading-gif");     
            if (data.result.length > 0) {
                address.town = data.result[0].city;
            }
        }).catch((err) => {   
            if(el != null) this.renderer.removeClass(el,"loading-gif");   
         
         });

    }
    private isNumber(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

  
}
export interface EmailBody{
                  receiverEmail: string;
                  replyTo:string,
                  subject: string;
                  emailText: string;
                  footerText: string;
                  body: string[];
                  footer: string[];
                  links?:Link[];
                  type:EmailType;
                  title: string;
                  companyName: string;
                  attachments: EmailAttachment[];
               }
               
               export interface EmailAttachment{
                   fileLocation:string;
                   fileName:string;
                   fileTempName:string;
                   isStored:boolean;
                   selected:Boolean;
                   localVariables?:any;
               }
               export enum  EmailType{
                 Offer,
                 Invoice,
                 Payslip,  
                 Undefined
               }
               export interface Link{
                   text:string;
                   url: string;              
               }
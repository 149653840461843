<ngx-ui-loader [loaderId]="'mainLoader'"></ngx-ui-loader>
<div  fxLayout="column" fxFlex.xl="65" fxFlex.lg="70" fxFlex.md="78" fxFlex.sm="95" fxFlex.xs="98" fxFlexOffset="15"  fxFlexOffset.sm="5" fxFlexOffset.xs="5">  
  
   
    <div style="margin-top: 50px; overflow-x: auto; margin-right: 30px;" fxLayout="row" [@trans]>
        <div fxLayout="column" class="subscription-box">
            <div class="subscription-head">
                <div class="subscription-title">BAS ABONNEMANG</div>
                <div class="subscription-price">Pris 0kr/månad</div>               
            </div>
            <div class="subscription-content">
                fakturor 10st/månad
            </div>
            <div class="subscription-content">
                offerter 3st/månad
            </div>
            <div class="subscription-content">
                Bokningskalender händelser 5st/månad
            </div>
            <div class="subscription-content">
                lönespecifikation 2st/månad
            </div>
            <div class="subscription-content">
                kundregister
            </div>
            <div class="subscription-content">
                PDF faktura
            </div>
            <div class="subscription-content">
                Skicka fakturor via e-post
            </div>
            <div class="subscription-content">
                Exportera ROT/RUT avdrag som xml fil
            </div>
            <div class="subscription-content">
                Skapa påminnelser
            </div>
            <div class="subscription-content">
                Skicka faktura via sms 5kr/sms
            </div>
            <div class="btn-box">
                <a style="width: 170px;" mat-raised-button href="https://www.fakturasidan.se/invoice/#/register?priceplan=1">Bli medlem</a>
            </div>
        </div>  
        <div fxLayout="column" class="subscription-box">
            <div class="subscription-head">
                <div class="subscription-title">BRONS ABONNEMANG</div>
                <div class="subscription-price">Pris 72kr/månad</div>              
                <div>ingen bindningstid *</div>
            </div>
            <div class="subscription-content">
                fakturor <strong>100st/månad</strong>
            </div>
            <div class="subscription-content">
                offerter  <strong>100st/månad</strong>
            </div>
            <div class="subscription-content">
                Bokningskalender händelser  <strong>370 st/månad</strong>
            </div>
            <div class="subscription-content">
                lönespecifikation <strong>10st/månad</strong>
            </div>
            <div class="subscription-content">
                kundregister
            </div>
            <div class="subscription-content">
                PDF faktura
            </div>
            <div class="subscription-content">
                Skicka fakturor via e-post
            </div>
            <div class="subscription-content">
                Exportera ROT/RUT avdrag som xml fil
            </div>
            <div class="subscription-content">
                Skapa påminnelser
            </div>
            <div class="subscription-content">
                Skicka faktura via sms 2kr/sms
            </div>
            <div class="btn-box">
                <a style="width: 170px;" mat-raised-button href="https://www.fakturasidan.se/invoice/#/register?priceplan=2">Bli medlem</a>
            </div>
        </div>        
    </div>   
    <div class="info-box">
        * BRONS abonnemang kostar 72 kr per månad exklusive moms, betalning sker automatiskt i 
        månadsvis. om en betalning misslyckas sänkas till BAS abonnemang.
    </div>
</div>
export class SystemError {

    message: string;
    status: number;
    statusText: string;
    public BindErrorResponse(obj:any){      
        this.message = obj?.error?.errorMessage? obj?.error?.errorMessage : obj?.message;
        this.status = obj?.status;
        this.statusText = obj?.statusText;     
    }


  }
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import{SessionService} from './SessionService';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Guid } from '../Models/Guid';


@Injectable({
    providedIn: 'root'  
  })

export class ApiService {
    headersObj:any;
    constructor(public Session: SessionService, private httpClient: HttpClient,  private router: Router) {
        this.headersObj ={
            'Content-Type':'application/json',            
            'x-correlation-id' : Guid.newGuid()  
        }
    }
   

    private async  SendRequest(method: string, url: string, authenticatedCall: boolean, data: any, arrayBufferResponse= false) { 
        
        if(authenticatedCall) this.headersObj.Authorization = 'bearer ' + this.Session.currentUser.token;
       
        let options:any ={
            headers: new HttpHeaders(this.headersObj)            
        }  
        if(arrayBufferResponse) options.responseType= 'arraybuffer';

        let httpRequest=  new HttpRequest(method, (environment.apiBaseUrl + url), data, options);      
        return await this.httpClient.request(httpRequest).toPromise().then((res: any) => {                                        
            return res.body;
        });    
       
    };   
      
    public home= {
        base: () => this.SendRequest('GET', 'version', false, null)           
    }
    public  email= {
        sendEmail: (email) =>  this.SendRequest('POST', 'send/external/email', false, email)
    }
    public helpers= {
        isUniqueEmail_UnRegisteredUser:  (email)=> this.SendRequest('POST', 'isUniqueEmail_UnRegisteredUser/' + email, false, null),
        isUniqueCompanyOrgnr_UnRegisteredUser:  (companyOrgnr)=> this.SendRequest('POST', 'isUniqueCompanyOrgnr_UnRegisteredUser/' + companyOrgnr, false, null),
        isUniqueCompanyName_UnRegisteredUser:  (companyName)=> this.SendRequest('POST', 'isUniqueCompanyName_UnRegisteredUser', false, { companyName: companyName }),
        reportError_UnRegisteredUser:  (error)=> this.SendRequest('POST', 'reportError_UnRegisteredUser', false, error),
        getCity:  (zipcode)=> this.SendRequest('GET', 'getCity/' + zipcode, false, null),
        getAddress:  (street)=> this.SendRequest('GET', 'getAddress/' + street, false, null),
        getCompanyByOrgNr:  (orgnr)=> this.SendRequest('GET', 'companyInfo/' + orgnr, false, null),
        
    } 

    public analytics={
        registerNewVisitors:  ()=> this.SendRequest('GET', 'bing/visitor', false, null),
    }
   

    

   
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule} from '@angular/router';
import{HomeComponent} from './Components/HomeComponent';
import { ContactComponent } from './Components/ContactComponent';
import { SubscriptionComponent } from './Components/SubscriptionComponent';
import { AddComponent } from './Components/AddComponent';

const routes: Routes = [
  { path: 'home', component: HomeComponent},
  { path: 'subscriptions', component: SubscriptionComponent},
  { path: 'contact', component: ContactComponent},
  { path: 'add/:code', component: AddComponent},
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {path: '**', redirectTo: 'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})], 
  exports: [RouterModule]
})
export class AppRoutingModule  { }


import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'  
  })

export class MockedData {
    
    public GetDataPerYear(year:number){
        let f=70;
        let t=180;
        let nom= 12;
        if(year == new Date().getFullYear()) {
            nom = new Date().getMonth() +1;
        }
        let n = this.RandomNumber(f, t);
        let notpaid= Math.floor((n * 0.05));

        let months = [];
        for (let index = 0; index < nom; index++) {
            let amount = this.RandomNumber(120000, 150000);
            let tinv =  this.RandomNumber(20, 50);
            let m = {month: index + 1, value:{from: this.DefaultDateFormat(new Date(year, index, 1)), to: this.DefaultDateFormat(new Date(year, index + 1, 0)), totalInvoices: tinv, totalAmount: amount}};
            months.push(m);
            
        }
        return {
            invoicesCount: n,
            paidInvoices: n - notpaid,
            nonPaidInvoices: notpaid,
            canceledInvoices:0,
            customersCount:120,
            income:{
                year: year,
                months: months
            }
        };
    }

   
    private RandomNumber(f:number, t:number){
       return  Math.floor(Math.random() * t) + f  ;
    }

    public DefaultDateFormat(date:Date|string): string{  
        date =  new Date(date);
        if(isNaN(date.getTime())) return null;
        let month = date.getMonth()+1;
        let day = date.getDate();
        return date.getFullYear() + '-' + (month < 10? '0' + month : month) + '-' +  (day < 10? '0' + day : day)

    }



}
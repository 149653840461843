<div style="height: 80vh;">
    <mat-toolbar color="primary">
      <mat-toolbar-row>
        <button  mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm>
          <mat-icon>menu</mat-icon>
        </button>
        <img width="170px"  src="../../assets/images/fakturasidan_se_allwhite.svg">    
        <span class="menu-spacer"></span>
      
       
        
        <div fxShow="true"  fxHide.lt-md>
          
          <!-- The following menu items will be hidden on both SM and XS screen sizes -->          
          <a  routerLink="home"  routerLinkActive="selected" mat-button><mat-icon>home</mat-icon>Hem</a>               
          <a  routerLink="subscriptions"  routerLinkActive="selected" mat-button><mat-icon>perm_contact_calendar</mat-icon>Abonnemang</a> 
          <a  routerLink="contact"  routerLinkActive="selected" mat-button><mat-icon>phone</mat-icon>Kontakt</a>    
        </div>
        <span class="menu-spacer-large" ></span>
        <div  fxShow="true">
          <a  href="https://www.fakturasidan.se/invoice/#/login"   mat-button><mat-icon svgIcon="login"></mat-icon> Logga in</a>
          <a  href="https://www.fakturasidan.se/invoice/#/register"   mat-button><mat-icon svgIcon="register"></mat-icon> Register</a> 
        </div>
        
      </mat-toolbar-row>
    </mat-toolbar>
   
    <mat-sidenav-container >
      <mat-sidenav  #sidenav>
        <mat-nav-list  (click)="sidenav.toggle()">
            <div  style="margin-top: 64px;"> 
                <a  routerLink="home" routerLinkActive="selected"   mat-list-item>Hem</a>              
                <a  routerLink="subscriptions" routerLinkActive="selected"   mat-list-item>Abonnemang</a> 
                <a  href="https://www.fakturasidan.se/invoice/#/login"    mat-list-item> Logga in</a>
                <a  href="https://www.fakturasidan.se/invoice/#/register"    mat-list-item> Register</a> 
                <a  routerLink="contact"  routerLinkActive="selected"  mat-list-item>Kontakt</a>                 
                <a  mat-list-item><mat-icon>close</mat-icon>Stäng</a>  
            </div>                
        </mat-nav-list> 
      </mat-sidenav>
      <mat-sidenav-content>    
        <div class="views-container">
          <p *ngIf="errorHandler.error.message" class="mainErrorMessage"> Error: {{errorHandler.error.message}}</p>
            <router-outlet></router-outlet>
        </div>
       
      </mat-sidenav-content>
    </mat-sidenav-container>
  
  </div>


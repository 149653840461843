import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'  
  })

export class IconsInjectorService{ 

    constructor( private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {} 
 
    public InjectIcon(iconName: string, iconImageUrl:string) {
       
         this.matIconRegistry.addSvgIcon(iconName, this.domSanitizer.bypassSecurityTrustResourceUrl(iconImageUrl));      
                  
    }

}
